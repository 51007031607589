<template>

    <div class="ar-on-boarding">

        <ar-on-boarding-steps :tablet="tablet"></ar-on-boarding-steps>
        
    </div>

</template>


<script>

import ArOnBoardingSteps from '@/components/ar-terrain/ArOnBoardingSteps'

export default {

    name: 'ArOnBoarding',

    props: ['tablet'],

    components: {
        ArOnBoardingSteps
    },

    data() {
        return {
            componentName: this.$options.name + ' View'
        }
    },

    computed: {

    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {

    }
    
}

</script>


<style lang="scss" scoped>

.ar-on-boarding {
    position: relative;
}

</style>