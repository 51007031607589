<template>

    <button :class="{'tablet' : tablet}">
        <span class="label">{{ label }}</span>
        <span class="icon mr-1" :class="[ icon ]" v-if="icon"></span>
    </button>

</template>


<script>

export default {

    name: 'ArButton',

    props: {
        label: {
            type: String,
            default: 'Button'
        },
        icon: {
            type: String,
            default: ''
        },
        tablet: {
            type: Boolean,
            default: false
        }
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component',
        }
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        
    }
    
}

</script>


<style lang="scss" scoped>

$btn-active-border-color:   rgba(63,89,153, 0.0);
$btn-focus-border-color:    rgba(63,89,153, 0.3);

$gradient-active-start:     rgba(63,89,153, 1.0);
$gradient-active-end:       rgba(97,200,209, 1.0);

$gradient-hover-start:      rgba(47,57,126, 1.0);
$gradient-hover-end:        rgba(98,174,210, 1.0);

button {
    background-color: transparent;
    margin: 0 auto;
    width: 230px;
    height: 50px;
    border: 5px solid $btn-active-border-color;
    border-radius: 100px;
    outline: none;
    display: flex;
    overflow: hidden;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    position: relative;
    z-index: 25;
    cursor: pointer;
    transition: border 0.6s ease-out;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, .25);
    border: none;

    &.tablet {
        width: 400px;
        height: 87px;

        .label {
            font-size: 2rem;
        }

        .icon {
            width: 34px;
            height: 28px;
            margin-left: 10px;

            &.arrow {
                background-size: 34px 22px;
            }

            &.retry {
                background-size: 20px 23px;
                width: 24px;
                height: 23px;
            }
        }

        &.last {
            width: 550px;
        }
    }

    &.last {
        width: 333px;

        & .icon {
            display: none;
        }

        & .label {
            margin-left: 0;
        }
    }

    &::before,
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        //z-index: 1;
    }

    &::before {
        background-image: linear-gradient(to right, $gradient-active-start, $gradient-active-end);
    }

    &::after {
        background-image: linear-gradient(to right, $gradient-hover-start, $gradient-hover-end);
        opacity: 0;
        //z-index: 2;
        transition: opacity 0.6s ease-out;
    }

    // :FOCUS
    &:focus {
        //border: 5px solid $btn-focus-border-color;
        transition: border 0.3s ease-out;

        &::after {
            opacity: 1;
            transition: opacity 0.3s ease-out;
        }

        & .arrow {
            background-image: url('../../assets/images/global/img-btn-arrow-hover.png');
            transition: background-image 0.2s ease-out;
        }
    }

    // :HOVER
    @media(hover: hover) {
        &:hover {

            &::after {
                opacity: 1;
                transition: opacity 0.3s ease-out;
            }

            & .arrow {
                background-image: url('../../assets/images/global/img-btn-arrow-hover.png');
                transition: background-image 0.2s ease-out;
            }
        }
    }

}

// LABEL
.label {
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    line-height: 1;
    text-transform: uppercase;
    color: $white-100;
    display: inline-block;
    position: relative;
    z-index: 5;
    margin-left: 2rem;
}

// ICON
.icon {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 24px;
    height: 20px;
    display: inline-block;
    position: relative;
    z-index: 5;
    margin-left: 10px;

    @media (min-width: 576px) { // RED (SM)
        margin-left: 0;
    }
    @media (min-width: 768px) { // GREEN (MD)

    }
    @media (min-width: 992px) { // BLUE (LG)

    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }

    &.arrow {
        background-image: url('../../assets/images/global/img-btn-arrow.png');
        background-size: 22px 14px;
        transition: background 0.2s ease-out;
    }

    &.retry {
        background-image: url('../../assets/images/global/img-btn-retry.png');
        background-size: 20px 23px;
        width: 24px;
        height: 23px;
        transition: background 0.2s ease-out;
    }
}

</style>
