<template>

    <div class="card">
        <div class="inner">
            <slot></slot>
        </div>
    </div>

</template>


<script>

export default {

    name: 'ArCard',

    props: {
        clouds: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {

        }
    }

}

</script>


<style lang="scss" scoped>
    .card {
        background: linear-gradient(135deg,  rgba(254,254,254, 0.9) 1%, rgba(234,237,247, 0.9) 100%);
        border-radius: 20px;
        border: 1px solid white;
        box-shadow: 0 0 50px rgba(6,7,34, 0.15);
        padding: 1rem 0;
        position: relative;
        width: 90%;

        & > .inner {
            position: relative;
            z-index: 10;
            width: 100%;
        }
    }
</style>