import { render, staticRenderFns } from "./ArButton.vue?vue&type=template&id=3566865b&scoped=true&"
import script from "./ArButton.vue?vue&type=script&lang=js&"
export * from "./ArButton.vue?vue&type=script&lang=js&"
import style0 from "./ArButton.vue?vue&type=style&index=0&id=3566865b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3566865b",
  null
  
)

export default component.exports