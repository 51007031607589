<template>

    <div class="ar-on-boarding-steps" :class="{'tablet' : tablet}">

        <transition name="fade" mode="out-in">
            <ar-card v-if="currentStep"
                  :key="'card_' + currentStep.step">
                <component :is="currentStep.component" :tablet="tablet"></component>
            </ar-card>
        </transition>

        <div class="nav" ref="nav">
            <ar-button @click.native="nextStepClickHandler"
                        :label="(currentStep.step === steps.length) ? labels.continue : labels.next"
                        icon="arrow"
                        :tablet="tablet"
                        :class="{ 'last': (currentStep.step === steps.length) }"
                        ref="buttonElement"></ar-button>
            <div v-show="(currentStep.step < steps.length)">
                <a @click="handleSkipClick" class="skip-link">{{ labels.skip }}</a>
            </div>
            <ul class="step-nav mr-2">
                <li v-for="(step, idx) in steps"
                    :key="'step-nav_' + idx"
                    :class="{ 'active': (currentStep.step === (idx + 1)) }"
                    @click="stepNavClickHandler(step.step)"></li>
            </ul>
        </div>
        
    </div>

</template>


<script>

import { mapGetters, mapActions } from 'vuex'
import { SET_CURRENT_STEP } from '@/store/arTerrain/actions'

import ArCard from './ArCard'
import ArButton from './ArButton'

export default {

    name: 'ArOnBoardingSteps',

    props: ['tablet'],

    components: {
        ArCard,
        ArButton
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component',
            labels: {
                next: 'Next',
                skip: 'Skip',
                continue: 'Explore South Col Route'
            }
        };
    },

    mounted() {
        //alert(this.tablet)
    },

    computed: {

        ...mapGetters('ArTerrain', {
            steps: 'onBoardingSteps',
            currentStep: 'currentStep'
        }),

        totalSteps() {
            return this.steps.length
        }

    },
    
    methods: {

        /**
         * @function handleSkipClick
         * @description
         *
         */
        handleSkipClick() {
            this.$router.push({ name: 'ArExperience' })
        },

        /**
         * @function nextStepClickHandler
         * @description
         */
        nextStepClickHandler() {
            if (this.currentStep.step < this.totalSteps) {
                this.setCurrentStep(this.steps[this.currentStep.step]);
                this.$refs.nav.style.opacity = 0
                let here = this
                let t_out = setTimeout(function(){
                    clearTimeout(t_out)
                    here.$refs.nav.style.opacity = 1
                },800)
            } else {
                this.handleSkipClick()
            } 
        },

        /**
         * @function stepNavClickHandler
         * @description
         * @param {Number} nextStep
         */
        stepNavClickHandler(nextStep){
            if (nextStep <= this.totalSteps) {
                this.setCurrentStep(this.steps[nextStep-1]);
                this.$refs.nav.style.opacity = 0
                let here = this
                let t_out = setTimeout(function(){
                    clearTimeout(t_out)
                    here.$refs.nav.style.opacity = 1
                },800)
            }
        },

        ...mapActions('ArTerrain', {
            setCurrentStep: SET_CURRENT_STEP
        })

    },

    beforeDestroy() {
        this.setCurrentStep(null);
    }

}

</script>


<style lang="scss" scoped>

.card {
    margin: 20px 20px 50px;
    max-width: 850px;
}

.ar-on-boarding-steps {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.tablet {

        & .card {
            margin: 40px 20px 50px;
        }

        & .skip-link {
            font-size: 30px;
        }

        & button {
            margin-top: -25px;
        }

        .step-nav {
            padding-right: 5px;

            &::before {
                width: 17px;
                height: 3px;
            }

            & > li {
                width: 20px;
                height: 20px;
                border: 3px solid #5db0d6;
                margin-left: 10px;

                &::before {
                    width: 10px;
                    height: 3px;
                    left: -13px;
                }
            }
        }
    }

    & .skip-link {
        display: block;
        color: #5db0d6;
        font-size: 20px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        cursor: pointer;
        margin-top: 10px;
    }
    
    & button {
        margin-top: -35px;
    }
}

.step-nav {
    list-style-type: none;
    margin: 15px 0 0 0;
    padding: 0 0 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 0rem;

    &::before {
        content: '';
        background: #5db0d6;
        width: 17px;
        height: 2px;
        position: relative;
    }

    & > li {
        background: white;
        width: 13px;
        height: 13px;
        border: 2px solid #5db0d6;
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 7px;

        &::before {
            content: '';
            background: #5db0d6;
            width: 7px;
            height: 2px;
            position: relative;
            left: -9px;
        }

        &.active {
            background: #5db0d6;
        }
    }
}

</style>
